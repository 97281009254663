//import { Component } from 'react';

import { useState , useEffect} from 'react';
import CardList from './components/card-list/card-list.component.jsx';
import SearchBox from './components/search-box/search-box.component.jsx';
import './App.css';

const App = () => {

    const [searchField, setSearchField] = useState(''); //array destructuring; give back an array of 2 values (value, setValue)
    const [monsters, setMonsters]= useState([]);

        //callback, dependencies
    useEffect(() => {
        fetch('https://jsonplaceholder.typicode.com/users')
            .then((response) => response.json())
            .then((users) => setMonsters(users)
            );
    }, []);



    const onSearchChange = (event) => {
        const searchFieldString = event.target.value.toLowerCase();
        setSearchField(searchFieldString);
    }

    const filteredMonsters = monsters.filter((monster) => {
        return monster.name.toLowerCase().includes(searchField);
    });

    return (
        <div className="App">

            <h1 className='app-title'>Monsters Rolodex</h1>

            <SearchBox
                className='monsters-search-box'
                onChangeHandler={onSearchChange}
                placeholder='search monsters'
            />
            <CardList monsters={filteredMonsters}/>
        </div>
    )

}
/*
class App extends Component {

    constructor() {
        super();

        this.state = {

            monsters: [],
            searchField: ''


        };
    }

    componentDidMount() {
        fetch('https://jsonplaceholder.typicode.com/users')
            .then((response) =>
                response.json()
            )
            .then((users) => this.setState(() => {
                return {monsters: users}
        },
                ()  => {
console.log(this.state)
                }


             )
          );
    }

onSearchChange = (event) => {
        console.log(event.target.value);
        const searchField = event.target.value.toLowerCase();
        this.setState(() => {
                return {searchField}
            },
            ()  => {
                console.log(this.state)
            }
        )


    }

    render()
  {

      const {monsters, searchField} = this.state;
      const {onSearchChange} = this;

      const filteredMonsters = monsters.filter((monster) => {
          return monster.name.toLowerCase().includes(searchField);
      });

    return (
        <div className="App">

            <h1 className='app-title'>Monsters Rolodex</h1>

            <SearchBox
                className='monsters-search-box'
                onChangeHandler={onSearchChange}
                placeholder='search monsters'
            />
            <CardList  monsters={filteredMonsters} />
        </div>
    );
  }
}
*/
export default App;
